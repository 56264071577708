
import Vue from "vue";
import { VContainer } from "vuetify/lib";
import moment from "moment";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
// Components
import DailyWorkReportSearchForm from "@/components/form/dailyWorkReport/DailyWorkReportSearchForm.vue";
import DailyWorkReportSearchTable from "@/components/table/dailyworkreport/DailyWorkReportSearchTable.vue";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";
import { DailyWorkReportSearchRequest } from "@/models/dailyWorkReport/request/DailyWorkReportSearchRequest";
import { DATE_FORMAT } from "@/defines";

const DATE_FROM = moment()
  .subtract(1, "week")
  .format(DATE_FORMAT.DATE);
const DATE_TO = moment().format(DATE_FORMAT.DATE);

const defaultForm = new DailyWorkReportSearchRequest({
  workDateFrom: DATE_FROM,
  workDateTo: DATE_TO,
  userId: null,
  itemName: null,
  tableName: null,
  acceptNo: null,
  size: null,
  specificationNo: null
});

export default Vue.extend({
  name: "DailyWorkReportSearch",
  mixins: [Common, ShowDialogs, Api],
  components: {
    VContainer,
    DailyWorkReportSearchForm,
    DailyWorkReportSearchTable
  },
  props: {
    form: {
      type: String,
      required: false,
      default: () => JSON.stringify(defaultForm)
    }
  },
  data() {
    return {
      isLoading: false,
      tableItems: [] as DailyWorkReportSearchResponse[]
    };
  },
  computed: {
    inputForm: {
      get(): DailyWorkReportSearchRequest {
        return new DailyWorkReportSearchRequest(JSON.parse(this.form));
      },
      set(newValue: DailyWorkReportSearchRequest) {
        this.$emit("form", newValue);
      }
    }
  }
});
