
import Vue from "vue";
import { VBtn, VIcon } from "vuetify/lib";

export default Vue.extend({
  name: "SearchButton",
  components: {
    VBtn,
    VIcon
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
