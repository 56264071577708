
import Vue, { PropType } from "vue";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
// Components
import { VCard, VIcon, VDataTable, VBadge, VTooltip } from "vuetify/lib";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";
import { DailyWorkReportSearchRequest } from "@/models/dailyWorkReport/request/DailyWorkReportSearchRequest";

export default Vue.extend({
  name: "DailyWorkReportSearchTable",
  mixins: [Common, ShowDialogs, Api],
  components: {
    VCard,
    VIcon,
    VDataTable,
    VBadge,
    VTooltip
  },
  props: {
    items: {
      type: Array as PropType<DailyWorkReportSearchResponse[]>,
      required: true,
      default: () => [] as DailyWorkReportSearchResponse[]
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    form: {
      type: Object as PropType<DailyWorkReportSearchRequest>,
      required: true
    }
  },
  data() {
    return {
      //isLoading: true, //読み込みフラグ
      isError: false, //エラーフラグ
      errorMessage: [] as string[], //エラーメッセージ
      header: [
        {
          text: "日付",
          value: "workDate"
        },
        {
          text: "日報種類",
          value: "tableName"
        },
        {
          text: "通番",
          value: "index"
        },
        {
          text: "作業者",
          value: "userName"
        },
        {
          text: "受注番号",
          value: "acceptNo"
        },
        {
          text: "品名",
          value: "itemName"
        },
        {
          text: "サイズ",
          value: "size"
        },
        {
          text: "仕様書",
          value: "specificationNo"
        },
        {
          text: "編集",
          value: "action"
        }
      ]
    };
  },
  computed: {
    isLoading: {
      get(): boolean {
        return this.loading;
      },
      set(value: boolean) {
        this.$emit("update:loading", value);
      }
    }
  },
  methods: {
    itemRowBackGround(item: DailyWorkReportSearchResponse) {
      return item.abnormality ? "abnormality-row" : "";
    },
    onEdit(value: DailyWorkReportSearchResponse) {
      const name = value.tableValue;
      const params = {
        date: value.workDate,
        machineCode: "",
        machineName: "",
        form: JSON.stringify(this.form)
      };
      if (value.machineCode) params.machineCode = value.machineCode;
      if (value.machineName) params.machineName = value.machineName;
      if (name) this.$router.push({ name: name, params: params });
    }
  }
});
