
import Vue, { PropType } from "vue";

//class
import { CheckboxGroupItems } from "@/components/input/CheckboxGroup.vue";

// Components
import {
  VRow,
  VCol,
  VSpacer,
  VCard,
  VCardActions,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent
} from "vuetify/lib";

import { DailyWorkReportSearchRequest } from "@/models/dailyWorkReport/request/DailyWorkReportSearchRequest";
import UserSelect from "@/components/input/select/UserSelect.vue";
import DailyWorkReportTableSelect from "@/components/input/select/DailyWorkReportTableSelect.vue";
import DatePicker from "@/components/input/picker/DatePicker.vue";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";

// mixins
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import SearchButton from "@/components/input/button/SearchButton.vue";

export default Vue.extend({
  name: "DailyWorkReportSearchForm",
  components: {
    DatePicker,
    VCol,
    VRow,
    VSpacer,
    VCard,
    VCardActions,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    UserSelect,
    DailyWorkReportTableSelect,
    SearchButton
  },
  props: {
    value: {
      type: Array as PropType<DailyWorkReportSearchResponse[]>
    },
    form: {
      type: Object as PropType<DailyWorkReportSearchRequest>,
      required: true
    }
  },
  data() {
    return {
      dateRange: [],
      isOpen: 0,
      isLoading: false,
      userSelectItems: [] as CheckboxGroupItems[] //作業者
    };
  },
  computed: {
    inputValue: {
      get(): DailyWorkReportSearchResponse[] {
        return this.value;
      },
      set(value: DailyWorkReportSearchResponse[]) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    async onClick() {
      console.log("DailyWorkReportSearchForm onClick start");
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        const param = this.form;
        const result = await api.$post<DailyWorkReportSearchResponse[]>(
          api.Paths.dailyWorkReport.search,
          param
        );
        if (result) {
          this.inputValue = result;
        }
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("DailyWorkReportSearchForm onClick end");
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    await this.onClick();
  }
});
